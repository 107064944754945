'use client';

import { createAppKit } from '@reown/appkit/react';
import React, { FC } from 'react';
import { WagmiProvider } from 'wagmi';

import { adapter, chains, projectId } from './config';

const metadata = {
  name: 'Formo',
  description: 'Easily create web3 forms with Formo',
  icons: ['/logo.svg'],
  url:
    process.env.NODE_ENV === 'production'
      ? process.env.NEXT_PUBLIC_PRODUCTION_URL!
      : process.env.NEXT_PUBLIC_DEVELOPMENT_URL!,
};

// This inject the config and state into the WagmiProvider
createAppKit({
  adapters: [adapter],
  networks: chains as any,
  metadata,
  projectId,
  themeMode: 'dark',
  themeVariables: {
    '--w3m-border-radius-master': '1px',
    '--w3m-accent': 'rgb(167, 229, 103)',
    '--w3m-font-family': 'var(--font-dm-sans)',
  },
});

type Web3ModalProviderProps = {
  children: React.ReactNode;
};

const Web3ModalProvider: FC<Web3ModalProviderProps> = ({ children }) => {
  return <WagmiProvider config={adapter.wagmiConfig}>{children}</WagmiProvider>;
};

export default Web3ModalProvider;
