import { WagmiAdapter } from '@reown/appkit-adapter-wagmi';
import { mainnet } from '@reown/appkit/networks';

export const projectId = process.env.NEXT_PUBLIC_CONNECT_WALLET_PROJECT_ID!;

export const chains = [mainnet];

export const adapter = new WagmiAdapter({
  networks: chains,
  projectId,
  ssr: true,
});
